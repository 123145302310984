<template>
  <base-loader v-if="loading"></base-loader>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col-md-6">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ $t(`sidebar.couriers_${this.$route.query.type}`) }}
          </h3>
        </div>
        <div class="col-md-6 text-right">
          <router-link :to="`/courier-form`" class="btn btn-primary btn-sm"
            ><i class="fas fa-plus"></i> {{ $t("couriers.new courier") }}
          </router-link>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table
        class="table align-items-center table-flush"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="couriers"
      >
        <!-- Table Head -->
        <template v-slot:columns>
          <th>#</th>
          <th>{{ $t("tables.name") }}</th>
          <th>{{ $t("tables.email") }}</th>
          <th>{{ $t("tables.phone") }}</th>
          <th>{{ $t("tables.status") }}</th>
          <th>{{ $t("tables.status") }}</th>
          <th class="text-right">{{ $t("tables.action") }}</th>
        </template>

        <!-- Table Body -->
        <template v-slot:default="row">
          <td class="budget">
            {{ row.item.id }}
          </td>
          <th scope="row">
            <div class="media align-items-center">
              <span class="avatar rounded-circle mr-3">
                <img
                  :alt="row.item.name + 'Image'"
                  :src="'https://nqal.co/LiveNqal/public/' + row.item.avatar"
                />
              </span>
              <div class="media-body">
                <span class="name mb-0 text-sm">{{ row.item.name }}</span>
              </div>
            </div>
          </th>
          <td class="budget">
            {{ row.item.email }}
          </td>
          <td class="budget">
            {{ row.item.phone }}
          </td>
          <td class="budget">
            <span v-if="row.item.online" class="badge badge-dot">
              <i class="bg-success"></i> {{ $t("couriers.online") }}
            </span>
            <span v-else class="badge badge-dot">
              <i class="bg-warning"></i> {{ $t("couriers.offline") }}
            </span>
          </td>
          <td class="budget">
            <span v-if="row.item.enable == 1" class="badge badge-dot">
              <i class="bg-success"></i> {{ $t("tables.enable") }}
            </span>
            <span v-else class="badge badge-dot">
              <i class="bg-warning"></i> {{ $t("tables.disable") }}
            </span>
          </td>
          <td class="budget text-right">
            <select
              class="btn btn-success btn-sm text-left"
              @change="chgCourierStatus(row.item, $event)"
            >
              <option value="">{{ $t("tables.change status") }}</option>
              <option :value="1">{{ $t("tables.enable") }}</option>
              <option :value="0">{{ $t("tables.disable") }}</option>
            </select>
            <router-link
              :to="`/couriers/${row.item.id}`"
              class="btn btn-primary btn-sm"
              ><i class="fas fa-eye"></i
            ></router-link>
            <router-link
              :to="`/courier-form/${row.item.id}`"
              class="btn btn-success btn-sm"
              ><i class="fas fa-edit"></i
            ></router-link>
          </td>
        </template>
      </base-table>
    </div>
    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!-- Pagination Component -->
      <base-pagination
        :page="page"
        :total_pages="total_pages"
        v-on:pagination-num="page = $event"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "couriers-table",
  props: {
    type: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      renderComponent: false,
      loading: false,
      couriers: "",
      couriersType: "",
      statuses: [
        ["available", `${this.$t("couriers.available")}`],
        ["busy", `${this.$t("couriers.busy")}`],
      ],
      targetStatus: "",
      page: 1,
      total_pages: "",
    };
  },
  created() {
    this.getCouriers(
      `admin/administration/couriers/${this.$route.query.type}?page=1`
    );
  },
  watch: {
    $route(to) {
      this.couriersType = to.query.type;
      this.getCouriers(
        `admin/administration/couriers/${this.$route.query.type}?page=1`
      );
    },
    page: function () {
      this.getCouriers(
        `admin/administration/couriers/${this.$route.query.type}?page=${this.page}`
      );
    },
  },
  methods: {
    getCouriers: function ($pagination) {
      this.loading = true;
      axios
        .get($pagination)
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.data.length > 0) {
              this.couriers = response.data.data;
            } else {
              this.couriers = "";
            }
            if (response.data.meta) {
              this.page = response.data.meta.current_page;
              this.total_pages = response.data.meta.last_page;
            } else {
              this.page = "";
              this.total_pages = "";
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
    // Change Courier Status
    chgCourierStatus: function ($courier) {
      this.loading = true;
      this.newStatus = event.target.value;
      axios
        .post("admin/administration/couriers/enable", {
          _method: "put",
          id: $courier.id,
          enable: this.newStatus,
        })
        .then((response) => {
          if (response.data.error) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
            });
          } else {
            if (response.data.status == 0) {
              this.$swal({
                position: "center",
                icon: "error",
                title: `${this.$t("validation.faild")}`,
                text: response.data.message,
              });
            } else {
              // let updatedId = response.data.data[0];
              this.couriers.filter((courier) => {
                // if (courier.id === updatedId.id) {
                if (courier.id === $courier.id) {
                  courier.enable = this.newStatus;
                }
              });
              this.$swal({
                position: "center",
                icon: "success",
                title: `${this.$t("validation.success")}`,
                showConfirmButton: false,
                timer: 1200,
              });
            }
          }
        })
        .catch(() => {
          if (!window.navigator.onLine) {
            this.$swal({
              position: "center",
              icon: "error",
              title: `${this.$t("validation.faild")}`,
              text: `${this.$t("validation.check network")}`,
            });
          }
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
